import React, { Component } from "react";
import { Button, Grid, Header, Dimmer, Loader, Image, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { rejectIfAnyNull } from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import { formatQuery } from "../controllers/utilities/string-utils";
import { getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall } from "../components/lib/ad-manage-api-lib";
import { navigate } from "../../.cache/gatsby-browser-entry";
import { getUA } from "react-device-detect";
import "../styles/bg.css";
import { AdIosaregionSwipetoslide } from "../components/react-slick/ad-iosaregion-swipetoslide";
import top from "../images/recharge/top.png";
import back from "../images/recharge/back.png";
import address from "../images/recharge/address.png";
import detal from "../images/recharge/detal.png";

import capiton from "../images/recharge/detal/hint.png";
import capitonok from "../images/recharge/detal/ok.png";

import bg from "../images/recharge/bg.png";
import box1 from "../images/recharge/box1.png";
import box3 from "../images/recharge/box3.png";
import base0 from "../images/recharge/base0.png";
import base1 from "../images/recharge/base1.png";

import { Link } from "gatsby";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import KaisakuApi from "../controllers/kaisaku-api";
import cookie from "react-cookies";

import b0 from "../images/recharge/date/0.png";
import b1 from "../images/recharge/date/1.png";
import b2 from "../images/recharge/date/2.png";
import b3 from "../images/recharge/date/3.png";
import b4 from "../images/recharge/date/4.png";
import b5 from "../images/recharge/date/5.png";
import b6 from "../images/recharge/date/6.png";
import b7 from "../images/recharge/date/7.png";
import b8 from "../images/recharge/date/8.png";
import b9 from "../images/recharge/date/9.png";
import bb from "../images/recharge/date/split.png";
import bbb from "../images/recharge/date/bb.png";
import bbbb from "../images/recharge/date/bbb.png";

import gb0 from "../images/recharge/gold/0.png";
import gb1 from "../images/recharge/gold/1.png";
import gb2 from "../images/recharge/gold/2.png";
import gb3 from "../images/recharge/gold/3.png";
import gb4 from "../images/recharge/gold/4.png";
import gb5 from "../images/recharge/gold/5.png";
import gb6 from "../images/recharge/gold/6.png";
import gb7 from "../images/recharge/gold/7.png";
import gb8 from "../images/recharge/gold/8.png";
import gb9 from "../images/recharge/gold/9.png";
import gbb from "../images/recharge/gold/split.png";
import gbbb from "../images/recharge/gold/bb.png";

import total from "../images/recharge/total.png";
import pack from "../images/recharge/pack.png";

import get from "../images/recharge/get.png";
import getok from "../images/recharge/getok.png";
import saleout from "../images/recharge/saleout.png";

import tgb0 from "../images/recharge/subitem/0.png";
import tgb1 from "../images/recharge/subitem/1.png";
import tgb2 from "../images/recharge/subitem/2.png";
import tgb3 from "../images/recharge/subitem/3.png";
import tgb4 from "../images/recharge/subitem/4.png";
import tgb5 from "../images/recharge/subitem/5.png";
import tgb6 from "../images/recharge/subitem/6.png";
import tgb7 from "../images/recharge/subitem/7.png";
import tgb8 from "../images/recharge/subitem/8.png";
import tgb9 from "../images/recharge/subitem/9.png";
import tgbt from "../images/recharge/subitem/t.png";
import tgbp from "../images/recharge/subitem/p.png";
import tgbbb from "../images/recharge/subitem/n.png";

import hint1_1 from "../images/recharge/hint1_1.png";
import hint1_2 from "../images/recharge/hint1_2.png";
import hint1_3 from "../images/recharge/hint1_3.png";

import btn1 from "../images/recharge/btn1.png";
import btn2 from "../images/recharge/btn2.png";

import g2000_off from "../images/recharge/item/g2000_off.png";
import g2000_on from "../images/recharge/item/g2000_on.png";

import g5000_off from "../images/recharge/item/g5000_off.png";
import g5000_on from "../images/recharge/item/g5000_on.png";

import g10000_off from "../images/recharge/item/g10000_off.png";
import g10000_on from "../images/recharge/item/g10000_on.png";

import g20000_off from "../images/recharge/item/g20000_off.png";
import g20000_on from "../images/recharge/item/g20000_on.png";
import g20000_obj_off from "../images/recharge/item/g20000_obj_off.png";
import g20000_obj_on from "../images/recharge/item/g20000_obj_on.png";


import g40000_off from "../images/recharge/item/g40000_off.png";
import g40000_on from "../images/recharge/item/g40000_on.png";
import g40000_obj_off from "../images/recharge/item/g40000_obj_off.png";
import g40000_obj_on from "../images/recharge/item/g40000_obj_on.png";

import g80000_off from "../images/recharge/item/g80000_off.png";
import g80000_on from "../images/recharge/item/g80000_on.png";
import g80000_obj_off from "../images/recharge/item/g80000_obj_off.png";
import g80000_obj_on from "../images/recharge/item/g80000_obj_on.png";

import g150000_off from "../images/recharge/item/g150000_off.png";
import g150000_on from "../images/recharge/item/g150000_on.png";
import g150000_obj_off from "../images/recharge/item/g150000_obj_off.png";
import g150000_obj_on from "../images/recharge/item/g150000_obj_on.png";

import g250000_off from "../images/recharge/item/g250000_off.png";
import g250000_on from "../images/recharge/item/g250000_on.png";
import g250000_obj_off from "../images/recharge/item/g250000_obj_off.png";
import g250000_obj_on from "../images/recharge/item/g250000_obj_on.png";

import g400000_off from "../images/recharge/item/g400000_off.png";
import g400000_on from "../images/recharge/item/g400000_on.png";

import g600000_off from "../images/recharge/item/g600000_off.png";
import g600000_on from "../images/recharge/item/g600000_on.png";
import g600000_obj_off from "../images/recharge/item/g600000_obj_off.png";
import g600000_obj_on from "../images/recharge/item/g600000_obj_on.png";

import g900000_off from "../images/recharge/item/g900000_off.png";
import g900000_on from "../images/recharge/item/g900000_on.png";

import g1300000_off from "../images/recharge/item/g1300000_off.png";
import g1300000_on from "../images/recharge/item/g1300000_on.png";




import addressbox from "../images/recharge/address/box.png";
import addressboxwd from "../images/recharge/address/boxwd.png";
import addresscancel from "../images/recharge/address/cancel.png";
import addressok from "../images/recharge/address/ok.png";
import addresssubmit from "../images/recharge/address/submit.png";


export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ready: false,
            postdata: false,
            open: false,
            open1: false,
            accessToken: '',
            showTime1: '',
            showAmount: '',
        };
        let { accessToken } = formatQuery(this.props.location.search);
        this.state.accessToken = accessToken;
    }

    async getTYBUserInfo() {
        if (!isValidSession(this.props) || (this.state.accessToken)) {
            let accessToken = this.state.accessToken;
            if (!accessToken) {
                accessToken = cookie.load("_accessToken");
            }
            return await KaisakuApi.getTYBUserInfo2(0, accessToken);
        } else {
            return await new KaisakuUserApi(this.props.session).getTYBUserInfo(0);
        }
    }

    async postTYBIsCNPlayer() {
        if (!isValidSession(this.props) || (this.state.accessToken)) {
            let accessToken = this.state.accessToken;
            if (!accessToken) {
                accessToken = cookie.load("_accessToken");
            }
            return await KaisakuApi.postTYBIsCNPlayer2(0, accessToken);
        } else {
            return await new KaisakuUserApi(this.props.session).postTYBIsCNPlayer(0);
        }
    }

    async componentDidMount() {
        let data = await this.getTYBUserInfo();
        if (data.status === 200) {
            console.log("1111");
            console.log(data.data);
            const showTime1 = dateFormat(data.data.startTime, "dd/MM/yyyy") + " - " + dateFormat(data.data.endTime, "dd/MM/yyyy");
            let showAmount = data.data.amount;
            if (showAmount > 9999999) {
                showAmount = 9999999;
            }
            let showAmount1 = showAmount.toString();
            const amountWidth = (16 + ((showAmount1.length-1) * 2)) + "vw"; 
            // const count = (7 - showAmount1.length) / 2;
            // for (let j = 0; j < count; j++) {
            //     showAmount1 = " " + showAmount1; //test
            // }

            // console.log("2222");
            // console.log(showAmount);
            if ((data.data.userAddressInfo) && (data.data.userAddressInfo.isCn === 1 || data.data.userAddressInfo.isCn === 0)) {
                navigate(`/x-recharge-page1${this.props.location.search}`);
            } else {
                this.setState({
                    ready: true,
                    showTime1: showTime1,
                    showAmount: showAmount1,
                    amountWidth: amountWidth,
                    data: data.data
                });
            }
        }

    }

    async onReceive(objId) {
        alert("领取 " + objId);

    }

    async onReturn() {
        navigate("/confirm-purchase");
    }

    onClose() {
        this.setState({
            open: false
        });
    }

    onOpen() {
        this.setState({
            open: true
        });
    }

    onClose1() {
        this.setState({
            open1: false
        });
    }

    async onClose11() {
        this.setState({
            postdata: true
        });
        let data = await this.postTYBIsCNPlayer();
        if (data.status === 200) {
            navigate(`/x-recharge-page1${this.props.location.search}`);
        }else{
            this.setState({
                open1: false,
                postdata: false
            });
        }
        
    }

    onOpen1() {
        this.setState({
            open1: true
        });
    }

    render() {
        return (
            <div textAlign='center' style={{
                height: "100vh",
                width: "100%",
                margin: "0 auto",
                backgroundColor: "#30221d"
            }} verticalAlign='middle'>
                
                <Dimmer page active={this.state.postdata}>
                        <Loader/>
                    </Dimmer>
                <div style={{ width: "100%" }} >
                    <Image style={{
                        width: "100%",
                    }} centered
                        src={top}
                    />

                    <div style={{ position: "absolute", top: "1%", left: "2%", width: "8%" }}><Image style={{
                        width: "100%",
                    }} centered
                        onClick={this.onReturn.bind(this)}
                        src={back}
                    /></div>



                    <div style={{ position: "absolute", top: "1%", right: "2%", width: "8%" }}><Image style={{
                        width: "100%",
                    }} centered
                        onClick={this.onOpen.bind(this)}
                        src={detal}
                    /></div>

                    {(this.state.showAmount !== "") && (<div style={{ position: "absolute", top: "35vw", right: "1.5vw", height: "6vw",width:`${this.state.amountWidth}` }}>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[0])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[1])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[2])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[3])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[4])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[5])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[6])}
                        /></div>

                    </div>)}

                    {(this.state.showTime1 !== "") && (<div style={{ position: "absolute", top: "43vw", left: "8vw", height: "5vw" }}>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[0])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[1])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[2])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[3])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[4])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[5])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[6])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[7])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[8])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[9])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[10])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[11])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[12])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[13])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[14])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[15])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[16])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[17])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[18])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[19])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[20])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[21])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[22])}
                        /></div>
                    </div>)}


                </div>


                {(this.state.ready) && (<>
                    <div>
                        <Image style={{
                            width: "100%",
                        }} centered
                            src={box1}
                        />

                    </div>
                    <div style={{
                        backgroundImage: `url(${bg})`, backgroundSize: '100% 100%',
                        minHeight: "75vh",
                        textAlign: "center",
                        margin: "auto"
                    }}>
                        <div style={{ height: "5vw" }}></div>
                        <div style={{ width: "45%", margin: "auto",paddingBottom:"5vw" }}>
                            <Image style={{
                                height: "100%",
                            }} centered
                                src={hint1_2}
                            />
                        </div>
                        <div style={{ width: "60%", margin: "auto" }}>
                            <Image style={{
                                height: "100%",
                            }} centered
                                src={hint1_3}
                            />
                        </div>
                        <div style={{ height: "2vw" }}></div>
                        <div style={{ width: "80%", margin: "auto" }}
                            onClick={() => {
                                navigate(`/x-recharge-page3${this.props.location.search}`);
                            }}
                        >
                            <Image style={{
                                height: "100%",
                            }} centered
                                src={btn1}
                            />
                        </div>
                        <div style={{ height: "2vw" }}></div>
                        <div style={{ width: "80%", margin: "auto" }}
                            onClick={this.onOpen1.bind(this)}
                        >
                            <Image style={{
                                height: "100%",
                            }} centered
                                src={btn2}
                            />
                        </div>

                    </div>
                    <div>
                        <Image style={{
                            width: "100%",
                        }} centered
                            src={box3}
                        />
                    </div>
                </>)}

                <Modal
                    style={{ width: '96%', maxWidth: 450, backgroundColor: "#252722" }}
                    open={this.state.open}
                >
                    <div style={{ width: "100%", marginBottom: "1%" }}>
                        <Image style={{
                            width: "100%",
                        }} centered
                            src={capiton}
                        />
                    </div>

                    <div style={{ width: "100%" }}><Image style={{
                        width: "100%",
                    }} centered
                        src={capitonok}
                        onClick={this.onClose.bind(this)}
                    /></div>
                </Modal>

                <Modal
                    style={{ width: '96%', maxWidth: 450, backgroundColor: "#252722" }}
                    open={this.state.open1}
                >
                    <div style={{ width: "100%" }}>
                        <Image style={{
                            width: "100%",
                        }} centered
                            src={addressboxwd}
                        />
                    </div>

                    <div style={{ width: "100%" }}>
                        <div style={{ float: "left", width: "50%" }}><Image style={{
                            width: "100%",
                        }} centered
                            src={addresscancel}
                            onClick={this.onClose1.bind(this)}
                        /></div>
                        <div style={{ float: "right", width: "50%" }}><Image style={{
                            width: "100%",
                        }} centered
                            src={addressok}
                            onClick={this.onClose11.bind(this)}
                        /></div>

                    </div>
                </Modal>
            </div>
        );
    }
});

function getObjCount(oId, data) {
    const data1 = data.ActivityInfo;
    for (let j = 0; j < data1.length; j++) {
        const item = data1[j];
        if (item.objId === oId) {
            return (item.maxCount - item.saleCount)
        }
    }
    return 0;
}

function returnBtnIamge(oId, data) {
    if (getObjstatus(oId, data) === true) {
        return get;
    } else {
        return getok;
    }
}

function getObjstatus(oId, data) {
    const data1 = data.userActivityInfo;
    if (data1) {
        for (let j = 0; j < data1.length; j++) {
            const item = data1[j];
            if (item.objId === oId) {
                return false;
            }
        }
    }
    return true;
}


function returnItemImage(code) {
    switch (code) {
        case "0":
            return tgb0;
        case "1":
            return tgb1;
        case "2":
            return tgb2;
        case "3":
            return tgb3;
        case "4":
            return tgb4;
        case "5":
            return tgb5;
        case "6":
            return tgb6;
        case "7":
            return tgb7;
        case "8":
            return tgb8;
        case "9":
            return tgb9;
        case "t":
            return tgbt;
        case "p":
            return tgbp;
    }
}


function returnBImage2(code) {
    switch (code) {
        case "0":
            return gb0;
        case "1":
            return gb1;
        case "2":
            return gb2;
        case "3":
            return gb3;
        case "4":
            return gb4;
        case "5":
            return gb5;
        case "6":
            return gb6;
        case "7":
            return gb7;
        case "8":
            return gb8;
        case "9":
            return gb9;
        case "-":
            return gbb;
        default:
            return gbbb;
    }
}

function returnBImage(code) {

    switch (code) {
        case "0":
            return b0;
        case "1":
            return b1;
        case "2":
            return b2;
        case "3":
            return b3;
        case "4":
            return b4;
        case "5":
            return b5;
        case "6":
            return b6;
        case "7":
            return b7;
        case "8":
            return b8;
        case "9":
            return b9;
        case "/":
            return bb;
        case "-":
            return bbb;
        default:
            return bbbb;
    }
}

function dateFormat(time, fmt) { // author: meizz
    const date = new Date(time);
    // console.log(date);
    let o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        "S": date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}


function isValidSession(props) {
    if (!props) return false;
    return !!props.session && !!props.session.accessToken;
}